
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.backdrop {
  min-height: 118px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 190px;
  padding-bottom: 36.73%;

  &--stretchy {
    background: no-repeat 50%;
    background-size: cover;
    background-color: #dfdfdb;
    background-image: url("~images/seller-profile/soi_desktop.jpg");
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@include hz-breakpoint-m-up {
  .backdrop {
    min-height: 250px;
  }
}

@include hz-breakpoint-l-up {
  .backdrop {
    min-height: 360px;
  }
}

.root {
  background-color: hz-color(background-brand);
  min-height: 52px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include hz-breakpoint-s-up {
    min-height: 76px;
  }
}

.image {
  align-items: center;
  background-color: hz-color(background-light);
  border-radius: 3px;
  border: 2px solid hz-color(background-default);
  bottom: 10px;
  box-shadow: 2px 2px 4px 0 hsla(0,0%,100%,.4);
  display: flex;
  height: 72px;
  justify-content: center;
  left: 16px;
  position: absolute;
  width: 72px;

  img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 3px;
  }

  &-placeholder {
    height: 50px;
    opacity: 0.6;
    width: 50px;
  }
}

@include hz-breakpoint-m-up {
  .image {
    bottom: 20px;
    height: 150px;
    width: 150px;
    left: 46px;

    &-placeholder {
      height: 100px;
      width: 100px;
    }
  }
}

.titleWrap {
  bottom: 10px;
  left: 96px;
  position: absolute;
  color: #ffffff;

  &-Name {
    @include hz-textStyle--title3;
    font-weight: 300;

    @include hz-breakpoint-m-up {
      @include hz-textStyle--title2;
    }
  }

  &-Slogan {
    margin-top: 4px;

    @include hz-breakpoint-m-up {
      @include hz-textStyle--title5;
      font-weight: 300;
      margin-top: hz-spacing(s)
    }
  }

  &-Name,
  &-Slogan {
    text-shadow: 0 2px 4px rgba(0, 0, 0, .4);
    color: var(--hz-color-focus--light);
  }

  @include hz-breakpoint-m-up {
    bottom: 20px;
    left: 46px + 150px + 20px;
  }
}
