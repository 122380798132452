.l-page {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-bottom: 40px;

  @include hz-breakpoint-m-up {
    padding-left: hz-spacing(xs);
    padding-right: hz-spacing(xs);
  }

  &--wide {
    @include hz-breakpoint-xl-up {
      max-width: 1200px;
    }

    @media screen and (min-width: 1320px) {
      max-width: 1320px;
    }

    @media screen and (min-width: 1480px) {
      max-width: 1480px;
    }
  }
}
