
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  padding: hz-spacing(s);
  border-top: hz-border(standard) solid hz-color(border-light);
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Mobile-first approach: Default flex-direction for smaller screens */
  flex-direction: column;

  /* Apply layout change for medium and above screens */
  @include hz-breakpoint-m-up {
    flex-direction: row;
  }
}

.title {
  margin: 0 0 hz-spacing(xxxs) 0;
  display: flex;
  flex-wrap: wrap;

  /* Default mobile styles */
  max-width: 100%;

  /* Adjust margin and width for medium screens and above */
  @include hz-breakpoint-m-up {
    margin: 0;
    max-width: 60%;
  }

  &--colored {
    color:hz-color(brand-primary);
  }
}

.links {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: hz-spacing(s);

  > a {
    margin: 0 -hz-spacing(xxxxs);
    &:hover {
      opacity: 0.7;
    }
  }
}

.copyLink {
  display: block;
  position: relative;
  margin: 0;
  white-space: nowrap;

  > span {
    margin: 0 hz-spacing(xxxxs);
  }

  > a {
    cursor: pointer;
    text-decoration: underline;
  }

  &--success {
    margin-left: hz-spacing(s);
    color: hz-color(signal-success-default);
  }
}

.copyLinkAnimation {
  position: absolute;
  right: -hz-spacing(m);
  bottom: -hz-spacing(xxxxs);
  display: block;
  opacity: 0;
  text-align: center;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -ms-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
  transition: opacity 1s ease-in;
}
.copyLinkAnimationAnimating {
  opacity: 1;
}