
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.contentWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.leftCol, .rightCol {
  width: 100%;
}

.rightCol {
  margin-top: hz-spacing(s);
}

@include hz-breakpoint-m-up {
  .contentWrap {
    flex-direction: row;
  }
  .leftCol {
    width: 66.66%;
  }
  .rightCol {
    width: 32.02%;
  }
  .rightCol {
    margin-top: 0;
  }
}
