
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  position: relative;
  @include hz-textStyle--bodyRegular;
  color: #fff;
}

.follow-user-tooltip {
  position: absolute;
  width: 300px;
  left: 12px;
  bottom: 32px;

  &.withTabs {
    bottom: 72px;
  }

  &.hz-Alert.hz-Alert--point-bottom:before {
    left: 10%;
  }

  @include hz-breakpoint-s-up {
    left: auto;
    right: 12px;
    bottom: 32px;

    &.withTabs {
      bottom: 82px;
    }

    &.hz-Alert.hz-Alert--point-bottom:before {
      left: 90%;
    }
  }
}

.tabWrap {
  padding-top: hz-spacing(xxs);
}
