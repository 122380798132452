
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  &__followLabel {
    display: none;

    @include hz-breakpoint-s-up {
      display: inline;
      margin-left: 12px;
    }
  }
}
