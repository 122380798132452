
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.list {
  @include hz-textStyle(body-regular);
  display: flex;
  background-color: hz-color(background-default);

  overflow: auto;
  max-height: 300px;
  flex-direction: column;
  padding: hz-spacing(s);
  margin-bottom: hz-spacing(xxs);
}

.item {
  margin-bottom: hz-spacing(s);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemLeft {
  flex-basis: 60%;
}

.itemRight {
  flex-basis: 35%;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: hz-spacing(xxs);
}
.title h3 {
  margin-right: hz-spacing(xxs);
}

.score,
.text,
.reason {
  margin-bottom: hz-spacing(s);
}

.date {
  padding: 0 hz-spacing(s);
}
