
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.header {
  padding: hz-spacing(s);
  padding-bottom: 0;
  border-top: 1px solid hz-color(border-light); 
  

}
.heading {
  display: flex;
  flex-direction: row;
  align-items: center;

}
.header h2 {
  margin-right: hz-spacing(s);
}

.score {
  @include hz-textStyle(body-regular);
  color: hz-color(signal-success-default);
}

.content {
  padding: hz-spacing(s);

}

.reviewsList {
  display: flex;
  background-color: hz-color(background-light);
  overflow: auto;
  max-height: 300px;
  flex-direction: column;
  @include hz-textStyle(body-regular);
  padding: hz-spacing(s);
}

.reviewsItem {
  margin-bottom: hz-spacing(s);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.reviewsItemLeft {
  flex-basis: 60%;
}
.reviewsItemRight {
  flex-basis: 35%;
}
.reviewsIframe {
  border: 0;
}
