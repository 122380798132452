#page-wrapper {
    min-height: 100%;
    margin-bottom: -196px;
}

// Sticky footer
#page-wrapper:after {
    content: "";
    display: block;
}

footer,
#page-wrapper:after {
    min-height: 196px;
}
