
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.wrap {
  padding: hz-spacing(s);
  padding-top: hz-spacing(xxs);
  @include hz-textStyle(body-regular)
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.value {
  color: hz-color(signal-success-default);
}
.name{
  color: #818A99;
  margin-left: hz-spacing(s);
}
