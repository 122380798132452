h1, .heading-1 {
  @include hz-textStyle(title-1)
}

h2, .heading-2 {
  @include hz-textStyle(title-2);
}

h3, .heading-3 {
  @include hz-textStyle(title-3);
}

h4, .heading-4 {
  @include hz-textStyle(title-4);
}

h5, .heading-5 {
  @include hz-textStyle(title-5)
}

h6, .heading-6 {
}

.page-header {
  @include hz-textStyle(title-1);
  margin-bottom: 25px;
  text-align: center;
}
