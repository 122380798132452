// ==========================================================================
// MP extensions
// ==========================================================================

body {
  line-height: 1;
}


// Remove default margin on headers and paragraphs
h1, h2, h3, h4, h5, h6,
p {
  margin: 0;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, p, blockquote, th, td, input, textarea {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

fieldset, img {
  border: 0;
}

caption {
  text-align: left;
}
