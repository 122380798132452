
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  display: flex;
  align-items: center;
  margin-bottom: hz-spacing(xxs);
}

.icon {
  margin-right: hz-spacing(xxs);
  width: 28px;
}

.text {
  @include hz-textStyle--bodyRegular;
}
