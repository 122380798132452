//
// DO NOT ADD STYLING HERE WHEN IT OCCURS IN A FEW PAGES.
// INSTEAD, MAKE A MODULE AND INCLUDE IT IN THE PAGE SASS FILE
//

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  @include hz-textStyle(body-regular)
}

a {
  color: hz-color('signal-link-default');

  &:hover {
    color: hz-color('signal-link-hover');
    text-decoration: none;
  }

  &:active {
    color: hz-color('signal-link-active');
  }
}

p {
  @include hz-textStyle(body-regular)
}

#page-wrapper {
  background-color: hz-color(background-default);
  width: 100%;
  overflow-x: hidden;
}
