
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  overflow: hidden;
  padding: hz-spacing(xs) hz-spacing(xs) hz-spacing(xs) hz-spacing(s);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.text {
  display: block;
  color: #818a95;
  line-height: 24px;
}

.infoWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: space-between;
  flex-grow: 2;
}
.infoWrap > * {
  padding: 0 hz-spacing(xs) 0 0;
}
.infoWrap span {
  @include hz-textStyle(body-regular-strong);
  color: hz-color(signal-action-default);
  cursor: pointer;
}

.buttonWrap {
  flex-grow: 1;
  text-align: right;
}

@include hz-breakpoint-m-up {
  .root {
    padding: hz-spacing(xs) hz-spacing(s);
    justify-content: flex-start;
  }
  .noProfile {
    padding: hz-spacing(xs) hz-spacing(xs) hz-spacing(xs) 216px;
    .text {
      padding-left: 0;
    }
  }
  .text {
    order: 2;
    margin-left: hz-spacing(xxxs);
  }
  .buttonWrap {
    order: 1;
    margin-left: hz-spacing(m);
  }
  .infoWrap {
    display: flex;
    flex-direction: row;
    > div {
      padding: hz-spacing(xxxs) 0;
    }
  }
}
