
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.emptyState {
  position: relative;
  text-align: center;
  height: 60vh;
  @include hz-breakpoint-m-up {
    height: auto;
    min-height: 400px;
  }
}

.emptyStateContent {
  position: relative;
  top: 120px;
}

.emptyStateText {
  margin: 20px 20%;
}

.emptyStateBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.skyWrapper {
  width: 100%;
  height: 120px;
  position: absolute;
  top: 0;

  @include hz-breakpoint-m-up {
    height: 200px;
  }
}

.sky {
  height: 100%;
  position: absolute;
  right: -30%;
  top: 0;
  width: 150%;
}

.cloud {
  perspective: 1000;
  backface-visibility: hidden;

  &.cloud1 {
    transform: translate3d(20px, 0px, 0px);
    animation: cloud1 80s linear infinite;
    animation-delay: 20s;
  }
  &.cloud2 {
    animation: cloud2 50s linear infinite;
    animation-delay: -30s;
  }
  &.cloud3 {
    animation: cloud3 70s linear infinite;
    animation-delay: -25s;
  }
  &.cloud4 {
    animation: cloud4 40s linear infinite;
    animation-delay: -4s;
  }
}

@keyframes cloud1 {
  0% {
    transform: translate3d(20px, 0px, 0px);
  }
  100% {
    transform: translate3d(-120px, 0px, 0px);
  }
}
@keyframes cloud2 {
  0% {
    transform: translate3d(70px, 0px, 0px);
  }
  100% {
    transform: translate3d(-120px, 0px, 0px);
  }
}
@keyframes cloud3 {
  0% {
    transform: translate3d(70px, 0px, 0px);
  }
  100% {
    transform: translate3d(-90px, 0px, 0px);
  }
}
@keyframes cloud4 {
  0% {
    transform: translate3d(70px, 0px, 0px);
  }
  100% {
    transform: translate3d(-70px, 0px, 0px);
  }
}
